.sider {
  position: absolute;
  /* min-height: 100vh; */
  height: 100vh;
  z-index: 1000;
}

.sider .sider-title {
  box-sizing: border-box;
  width: 254px;
  display: flex;
  align-items: center;
  padding: 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #d8d8d8;
}

.sider .sider-title .anticon-menu-unfold {
  cursor: pointer;
}

.sider .sider-title span {
  white-space: normal;
  margin-left: 4px;
}

.sider .sider-title .small-title {
  font-size: 14px !important;
}

.jnm-menu .ant-menu-item {
  position: relative;
  box-sizing: border-box;
  width: 254px !important;
  height: 46px !important;
  line-height: 46px !important;
  display: flex;
  align-items: center;
  padding: 0 23px !important;
  margin-left: 16px;
  margin-right: 16px;
}

.jnm-menu .ant-menu-item .beta::after {
  content: '';
  position: absolute;
  width: 34px;
  height: 18px;
  right: 0px;
  top: 0px;
  background-image: url('../assets/beta.png');
  background-repeat: no-repeat;
  background-size: 34px 18px;
}

.jnm-menu .ant-menu-item-active {
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: 254px 46px;
}

.jnm-menu .ant-menu-item-selected {
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: 254px 46px;
  border-right: none !important;
}

.jnm-menu .ant-menu-item-active span {
  color: #fff;
}

.jnm-menu .ant-menu-item-selected span {
  color: #fff;
}

.jnm-menu .icon {
  width: 18px;
  height: 18px;
  margin-right: 14px;
  vertical-align: middle;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

.jnm-menu .ant-menu-item .icon1 {
  background-image: url('../assets/icon1.png');
}

.jnm-menu .ant-menu-item .icon2 {
  background-image: url('../assets/icon2.png');
}

.jnm-menu .ant-menu-item .icon3 {
  background-image: url('../assets/icon3.png');
}

.jnm-menu .ant-menu-item .icon4 {
  background-image: url('../assets/icon4.png');
}

.jnm-menu .ant-menu-item .icon5 {
  background-image: url('../assets/icon5.png');
}

.jnm-menu .ant-menu-item .icon6 {
  background-image: url('../assets/icon6.png');
}

.jnm-menu .ant-menu-item .icon7 {
  background-image: url('../assets/icon7.png');
}

.jnm-menu .ant-menu-item-active .icon1 {
  background-image: url('../assets/icon1-g.png');
}

.jnm-menu .ant-menu-item-selected .icon1 {
  background-image: url('../assets/icon1-g.png');
}

.jnm-menu .ant-menu-item-active .icon2 {
  background-image: url('../assets/icon2-g.png');
}

.jnm-menu .ant-menu-item-selected .icon2 {
  background-image: url('../assets/icon2-g.png');
}

.jnm-menu .ant-menu-item-active .icon3 {
  background-image: url('../assets/icon3-g.png');
}

.jnm-menu .ant-menu-item-selected .icon3 {
  background-image: url('../assets/icon3-g.png');
}

.jnm-menu .ant-menu-item-active .icon4 {
  background-image: url('../assets/icon4-g.png');
}

.jnm-menu .ant-menu-item-selected .icon4 {
  background-image: url('../assets/icon4-g.png');
}

.jnm-menu .ant-menu-item-active .icon5 {
  background-image: url('../assets/icon5-g.png');
}

.jnm-menu .ant-menu-item-selected .icon5 {
  background-image: url('../assets/icon5-g.png');
}

.jnm-menu .ant-menu-item-active .icon6 {
  background-image: url('../assets/icon6-g.png');
}

.jnm-menu .ant-menu-item-selected .icon6 {
  background-image: url('../assets/icon6-g.png');
}

.jnm-menu .ant-menu-item-active .icon7 {
  background-image: url('../assets/icon7-g.png');
}

.jnm-menu .ant-menu-item-selected .icon7 {
  background-image: url('../assets/icon7-g.png');
}

.jnm-menu span {
  vertical-align: middle;
  color: #666;
}

.ant-layout-sider-children {
  height: 100vh;
  position: relative;
}
/* .ant-layout-sider {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  flex: 0 !important;
} */

.jnm-menu-footer {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  left: 0;
  bottom: 0;
}

.jnm-menu-footer .jnm-menu-box {
  padding: 12px 32px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jnm-menu-footer .jnm-menu-box div {
  text-align: center;
}

.jnm-menu-footer .ant-menu-item {
  height: auto !important;
  line-height: 1em !important;
  padding: 12px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jnm-menu-footer .ant-menu-item div:first-child {
  margin-bottom: 6px;
}

.jnm-menu-footer .ant-menu-item:hover {
  color: rgba(0, 0, 0, 0.65) !important;
}

.jnm-menu-footer .ant-menu-item-selected {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.jnm-menu-footer .ant-menu-item-selected::after {
  border-right: 0px !important;
}

.jnm-menu-title .ant-menu-item {
  height: auto !important;
  line-height: 1em !important;
  padding: 0px !important;
  padding: 0 23px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.jnm-menu-title .ant-menu-item:hover {
  color: rgba(0, 0, 0, 0.65) !important;
}

.jnm-menu-title .ant-menu-item-selected {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.jnm-menu-title .ant-menu-item-selected::after {
  border-right: 0px !important;
}
