.moneyBox {
  width: 310px;
  height: 180px;
  /* height: auto; */
  background: #f8fbff;
  float: left;
  margin-right: 12px;
}
.moneyItem {
  padding: 10px 10px;
  font-size: 14px;
  color: #252d47;
}
.moneys {
  padding: 5px 15px;
  font-size: 25px;
  color: #252d47;
}
.severBox .box .title {
  width: 165px;
  height: 44px;
  border: 1px solid rgba(241, 243, 247, 1);
  text-align: center;
  line-height: 44px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
}
.buttonSure {
  text-align: right;
}

.btnbx {
  width: 100%;
  position: absolute;
  bottom: 25px;
  left: 0;
}
.weChart {
  width: 120px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #20ba58;
  color: #20ba58;
  border-radius: 20px;
  position: relative;
  top: -42px;
  left: 180px;
}
/* 工作台 */
.benchWorkItems {
  width: 301px;
  display: inline-block;
  margin-right: 12px;
  box-shadow: 0px 0px 4px 0px rgba(65, 116, 238, 0.16);
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.benchWorkItems:hover {
  box-shadow: 0px 0px 4px 0px rgba(65, 116, 238, 0.4);
}
.benchWorkItems :nth-child(3n + 2) {
  margin-right: 0;
}
.benchWorkItems .description {
  width: 90%;
  text-align: justify;
}
.tops {
  width: 100%;
  height: 115px;
}
.bottoms {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #f9f9fa;
}
.bottoms a {
  font-size: 14px;
  /* color: #1890ff; */
}
.lefts {
  width: 24%;
  float: left;
}
.lefts img {
  width: 48px;
  margin-left: 25%;
  margin-top: 20px;
}
.rights {
  width: 76%;
  float: right;
}
.title {
  width: 96%;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 2%;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}
.description {
  font-size: 14px;
  width: 96%;
  margin-left: 2%;
  text-align: justify;
  color: #aaa;
}

.bboxs {
  width: 100%;
  margin-top: -25px;
}
.sboxs {
  width: 50%;
  float: left;
}
.sboxsitem {
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  color: #252d47;
}
.sboxsmoney {
  width: 100%;
  padding: 0px 10px;
  font-size: 13px;
  color: #252d47;
}

.item {
  /* float: left; */
  line-height: 30px;
}
.typeitem {
  /* float: left;
      margin-right: 8px; */
  float: left;
  height: 32px;
  line-height: 32px;
  padding-right: 8px;
  background: #ddd;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0px;
  text-align: center;
}
.selitems {
  float: left;
}
.itemname {
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  margin: 20px 0;
}
.tips {
  width: 80px;
  height: 34px;
  line-height: 31px;
  background: url('/img/oTips.png');
  background-size: 100% 100%;
  color: white;
  position: absolute;
  top: -2px;
  right: -6px;
  text-align: center;
  font-size: 12px;
}
.tips2 {
  background: url('/img/gTips.png');
  background-size: 100% 100%;
  color: #4a546f;
  text-align: right;
  padding-right: 8px;
}
.topBanner {
  background: url(/img/workBg.png);
  background-size: 100% 100%;
  text-align: center;
  color: white;
  height: 131px;
  letter-spacing: 2px;
}
.topBanner p {
  font-size: 13px;
  padding-top: 100px;
}
.rightWorkBox {
  position: relative;
  padding-top: 0;
}
.jrButton {
  position: absolute;
  right: 0px;
}
.rightWorkBox a {
  font-size: 17px;
}
.workbench {
  width: 980px;
  margin: 0 auto;
  margin-top: 20px;
}
.workbench .innerBox {
  margin: 0;
  width: 100%;
  margin-top: 20px;
}
.diaoyangongchangerweima {
  width: 260px;
  height: 260px;
  margin-left: 110px;
}
.spMenu {
  text-align: center;
  max-height: 300px;
  overflow: auto;
  max-width: 300px;
}
.spBtn .bottoms {
  color: #737c97 !important;
}
.spMenu a {
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.rightWorkBox .textFont {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.rightWorkBox .anticon {
  vertical-align: middle;
  position: relative;
  bottom: 6px;
}
.lodingBox {
  height: 400px;
  line-height: 400px;
  text-align: center;
}

/* 信息详情 */
.detailsTops {
  width: 100%;
  height: 120px;
  background: white;
  margin-top: 20px;
  text-align: center;
}
.detailsContents {
  width: 100%;
  height: calc(100vh - 335px);
  border: 1px solid #d6e9ff;
  background: #edf8ff;
  color: #4e6886;
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: justify;
  padding: 20px;
  overflow: auto;
  padding-bottom: 0;
}
.noticeInfoBox {
  width: 420px;
}
.noticeInfoBox .ant-badge {
  width: 100px;
  text-align: center;
}
.noticeInfoBox .foot {
  /* height: 35px;
    line-height: 35px;
    width: 100%;
    text-align: center;
    background: #bd192d;
    opacity: 0.1; */
  height: 35px;
  line-height: 35px;
  width: 100%;
  text-align: center;
  background: #f5b8bf;
}
.noticeInfoBox .foot a {
  display: inline-block;
  /* border-right: 1px solid #bd192d; */
  width: 50%;
  /* color: #1890ff; */
  font-size: 14px;
  transition: 0.3s;
}
.noticeInfoBox .foot a:hover {
  background: #bd192d;
  color: white;
}
.noticeInfoBox .foot a {
  color: #fff;
}
.noticeInfoBox .foot a:nth-child(2) {
  border-right: 0;
}
.noticeInfoBox ul {
  padding: 0 !important;
}
.noticeInfoBox ul li {
  /* border-bottom: 1px solid #eeeeee; */
  font-size: 12px;
}
.noticeInfoBox ul li:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
.noticeInfoBox ul li h3 {
  font-size: 14px;
}
.noticeInfoBox .noData {
  text-align: center;
  padding-bottom: 20px;
}
.ant-badge-dot {
  width: 6px;
  height: 6px;
  background: #ffffff !important;
  right: -2px;
  top: -2px;
  border-radius: 50%;
}
.ant-scroll-number-only-unit {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ant-badge-count {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-popover .ant-popover-inner {
  box-shadow: none !important;
  border: none !important;
}
